<template>
  <div class="content">
    <app-cells position="between">
      <h1 class="title title--theme">Редактирование Письмо-вызов</h1>
      <app-button
        v-if="userIsHead && (form.status === 1 || form.status === 3)"
        theme="error"
        size="small"
        @click="onShowModal"
      >
        Удалить
      </app-button>
    </app-cells>
    <div v-if="!userIsAdmin && form.status === 3 && form.comment_reject" class="notice notice--mb notice--red">
      <div class="notice__title">
        <img src="@/assets/img/info-icon-red.svg" alt="Info icon">
        <span>Комментарий отказа</span>
      </div>
      <p class="notice__text">
        {{ form.comment_reject }}
      </p>
    </div>
    <app-cells v-if="userIsAdmin || (userIsHead && form.status === 4)" position="start">
      <app-button
        size="link"
        :class="{'btn--loading': download_app}"
        @click="onDownloadApplication"
      >
        <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
        <span>Скачать письмо-вызов в PDF</span>
      </app-button>
    </app-cells>
    <form @submit.prevent="onCheckForm">
      <app-grid class="grid--indent">
        <template #item-1>
          <app-form-group required label="Дата письма" label-for="date">
            <app-input
              v-model="form.date"
              v-mask="'99.99.9999'"
              :error="$v.form.date.$error"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              id="date"
              @input.native="$v.form.date.$touch"
              @paste.native.prevent
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.date.$dirty">
                <div v-if="!$v.form.date.underscorePresent">Заполните поле полностью</div>
                <div v-if="!$v.form.date.required">Обязательное поле</div>
                <div v-if="!$v.form.date.contractsMinDate">Дата должна быть после {{ contracts_min_date }}</div>
                <div v-if="!$v.form.date.contractsMaxDate">Дата должна быть по {{ contracts_max_date }}</div>
              </div>
            </template>
          </app-form-group>
          <app-form-group required label="Ваш адрес электронной почты" label-for="email">
            <app-input
              v-model="form.email"
              id="email"
              type="email"
              placeholder="Введите значение"
              :error="$v.form.email.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.email.$dirty && !$v.form.email.required">Обязательное поле</div>
              <div v-if="$v.form.email.$dirty && !$v.form.email.email">Некорректный формат электронной почты</div>
            </template>
          </app-form-group>
          <app-form-group label="Адрес электронной почты организации, куда необходимо отправить подготовленное письмо" label-for="email_org">
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <span>Если не указан, подготовленное письмо будет отправлено на ваш адрес электронной почты</span>
                </template>
              </app-tooltip>
            </template>
            <app-input
              v-model="form.email_org"
              id="email_org"
              type="email"
              placeholder="Введите значение"
              :error="$v.form.email_org.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.email_org.$dirty && !$v.form.email_org.email">Некорректный формат электронной почты</div>
            </template>
          </app-form-group>
          <app-form-group label="Должность лица, которому адресовано письмо (в дательном падеже, ЗАГЛАВНЫМИ БУКВАМИ)" label-for="position" required>
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <span>Например: РЕКТОРУ или ПРОРЕКТОРУ ПО СОЦИАЛЬНОЙ РАБОТЕ или ДИРЕКТОРУ</span>
                </template>
              </app-tooltip>
            </template>
            <app-input
              v-model="form.position"
              id="position"
              placeholder="Введите значение"
              :error="$v.form.position.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.position.$dirty && !$v.form.position.required">Некорректный формат электронной почты</div>
            </template>
          </app-form-group>
          <app-form-group label="Полное наименование организации в соответствии с уставом (в родительном падеже, ЗАГЛАВНЫМИ БУКВАМИ)" label-for="org" required>
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <span>
                    Например: ФЕДЕРАЛЬНОГО ГОСУДАРСТВЕННОГО БЮДЖЕТНОГО ОБРАЗОВАТЕЛЬНОГО УЧРЕЖДЕНИЯ ВЫСШЕГО
                    ОБРАЗОВАНИЯ "КРЕЖСКИЙ НАЦИОНАЛЬНЫЙ ИССЛЕДОВАТЕЛЬСКИЙ ПОЛИТЕХНИЧЕСКИЙ УНИВЕРСИТЕТ")
                  </span>
                </template>
              </app-tooltip>
            </template>
            <app-input
              v-model="form.org"
              id="org"
              placeholder="Введите значение"
              :error="$v.form.org.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.org.$dirty && !$v.form.org.required">Некорректный формат электронной почты</div>
            </template>
          </app-form-group>
          <app-form-group
            label="Инициалы и фамилия лица, которому адресовано письмо (в дательном падеже, ЗАГЛАВНЫМИ БУКВАМИ, с пробелом между инициалами и фамилией)"
            label-for="initials_surname"
            required
          >
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <span>
                    Например: В.Г. ТЕРЕХОВУ
                  </span>
                </template>
              </app-tooltip>
            </template>
            <app-input
              v-model="form.initials_surname"
              id="initials_surname"
              placeholder="Введите значение"
              :error="$v.form.initials_surname.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.initials_surname.$dirty && !$v.form.initials_surname.required">Некорректный формат электронной почты</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group required label="Выберите окончание для слова Уважаемый(ая)">
            <app-cells position="start" :indent="false">
              <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
                <span class="radio__text">ый</span>
                <input
                  v-model="form.ending_for_dear"
                  type="radio"
                  :value="true"
                />
                <span class="radio__radiomark"></span>
              </label>
              <label class="radio" :class="{ 'radio--readonly': readonlyCondition }">
                <span class="radio__text">ая</span>
                <input
                  v-model="form.ending_for_dear"
                  type="radio"
                  :value="false"
                />
                <span class="radio__radiomark"></span>
              </label>
            </app-cells>
            <template #error>
              <div v-if="$v.form.ending_for_dear.$dirty && !$v.form.ending_for_dear.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            label="Имя и отчество лица, которому адресовано письмо (в именительном падеже, с заглавной буквы)"
            label-for="person_whom"
            required
          >
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <span>
                    Например: Владимир Геннадьевич
                  </span>
                </template>
              </app-tooltip>
            </template>
            <app-input
              v-model="form.person_whom"
              id="person_whom"
              placeholder="Введите значение"
              :error="$v.form.person_whom.$error"
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.person_whom.$dirty && !$v.form.person_whom.required">Некорректный формат электронной почты</div>
            </template>
          </app-form-group>
          <app-form-group required label="Период направления участников делегации на Фестиваль (с)" label-for="period_from">
            <app-input
              v-model="form.period_from"
              v-mask="'99.99.9999'"
              :error="$v.form.period_from.$error"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              id="period_from"
              @paste.native.prevent
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.period_from.$dirty && !$v.form.period_from.underscorePresent">Заполните поле полностью</div>
              <div v-if="$v.form.period_from.$dirty && !$v.form.period_from.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Период направления участников делегации на Фестиваль (по)" label-for="period_to">
            <app-input
              v-model="form.period_to"
              v-mask="'99.99.9999'"
              :error="$v.form.period_to.$error"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              id="period_to"
              @paste.native.prevent
              :readonly="readonlyCondition"
            />
            <template #error>
              <div v-if="$v.form.period_to.$dirty && !$v.form.period_to.underscorePresent">Заполните поле полностью</div>
              <div v-if="$v.form.period_to.$dirty && !$v.form.period_to.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
      </app-grid>
      <app-form-group
        required
        label="Прошу рассмотреть возможность направления на Фестиваль кого? (в именительном падеже, обычно со строчной буквы, без точки в конце)"
      >
        <div class="form-table form-table--double" :class="{'form-table--readonly': readonlyCondition}">
          <div class="form-table__head">
            <div class="form-table__item">Фамилия, имя, отчество</div>
            <div class="form-table__item">Должность</div>
          </div>
          <div
            v-for="(item, index) in form.list"
            :key="index"
            class="form-table__row"
          >
            <button v-if="index > 0" @click="onDeleteItem(index)" class="link link--absolute"
                    type="button">
            </button>
            <div class="form-table__item" :class="{'form-table__item--error': $v.form.list.$each[index].fio.$error}">
              <input
                :id="`name${index}`"
                type="text"
                :name="`name${index}`"
                v-model.trim="item.fio"
              />
            </div>
            <div class="form-table__item" :class="{'form-table__item--error': $v.form.list.$each[index].position.$error}">
              <input
                :id="`executor${index}`"
                type="text"
                :name="`executor${index}`"
                v-model.trim="item.position"
              />
            </div>
          </div>
        </div>
        <app-button size="link" class="btn--link-icon" @click="onCloneItem" type="button">Добавить строку</app-button>
      </app-form-group>
      <div v-if="userIsAdmin" class="color-panel color-panel--indent">
        <app-cells position="start">
          <h2 class="title">Управление</h2>
        </app-cells>
        <app-grid items="3" col="3">
          <template #item-1>
            <app-form-group label="Служебный комментарий">
              <app-textarea
                v-model="form.comment_staff"
                id="comment_staff"
                placeholder="Введите комментарий"
              />
            </app-form-group>
          </template>
          <template #item-2>
            <app-form-group label="Комментарий отказа">
              <app-textarea
                v-model="form.comment_reject"
                id="comment_reject"
                placeholder="Введите комментарий"
              />
            </app-form-group>
          </template>
          <template #item-3>
            <app-form-group label="Статус">
              <v-select
                v-model="form.status"
                :reduce="item => item.id"
                :options="status_options"
                :filterable="false"
                :clearable="false"
                :searchable="false"
                label="name"
                placeholder="Выберите значение"
                class="select"
              >
                <template #open-indicator>
                  <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                    <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                  </svg>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
                <template #no-options>Ничего не найдено</template>
              </v-select>
            </app-form-group>
            <app-cells>
              <app-button ref="submit" :disabled="$v.form.$error">Сохранить изменения</app-button>
            </app-cells>
          </template>
        </app-grid>
      </div>
      <app-cells v-if="!userIsAdmin && (form.status === 1 || form.status === 3)">
        <app-button
          @click="onSendFormWithChangeStatus(form.status)"
          type="button"
          ref="submit"
          theme="transparent"
          :disabled="$v.form.$error"
        >
          Сохранить
        </app-button>
        <app-button
          @click="onSendFormWithChangeStatus(2)"
          type="button"
          ref="submitReview"
          :disabled="$v.form.$error"
        >
          Отправить на проверку
        </app-button>
      </app-cells>
    </form>
    <modal
      name="delete-modal"
      :width="750"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal">
        <div class="modal__title modal__title--center-black">Подтверждение действия</div>
        <p class="modal__text">
          Вы собираетесь удалить Письмо-вызов - ID: {{ form.id }}.
          Это действие невозможно будет отменить и данные будут удалены безвозвратно. Вы уверены, что хотите это сделать?
        </p>
        <app-cells position="center">
          <app-button size="small" @click="onDeleteCallLetter" ref="submitDelete">Да</app-button>
          <app-button size="small" @click="onHideModal">Нет</app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import {
  deleteCallLetter,
  getCallLetter,
  getCallLetterExport, getDelegations,
  getStatus,
  putCallLetter
} from '@/http'
import IconTooltip from '@/components/icons/IconTooltip'
import { dateFormatting } from '@/helpers'
import { parse } from 'date-fns'

const contracts_min_date_parsed = parse(process.env.VUE_APP_CONTRACTS_MIN_DATE, 'dd.MM.yyyy', new Date())
const contracts_max_date_parsed = parse(process.env.VUE_APP_CONTRACTS_MAX_DATE, 'dd.MM.yyyy', new Date())

const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

const contractsMaxDate = value => {
  if (!value) return true
  else {
    const dt = parse(value, 'dd.MM.yyyy', new Date())
    return (!dt || dt <= contracts_max_date_parsed)
  }
}

const contractsMinDate = value => {
  if (!value) return true
  else {
    const dt = parse(value, 'dd.MM.yyyy', new Date())
    return (!dt || dt >= contracts_min_date_parsed)
  }
}

export default {
  name: 'CallLetterEdit',
  components: {
    IconTooltip,
  },
  data() {
    return {
      form: {
        list: [{fio: '', position: ''}],
      },
      contracts_min_date: process.env.VUE_APP_CONTRACTS_MIN_DATE,
      contracts_max_date: process.env.VUE_APP_CONTRACTS_MAX_DATE,
      status_to_set: null,
      status_options: [],
      download_app: false,
      delegation_options: [],
      delegation_name: '',
    }
  },
  validations: {
    form: {
      email: { required, email },
      email_org: { email },
      position: { required },
      org: { required },
      initials_surname: { required },
      ending_for_dear: { required },
      person_whom: { required },
      list: {
        $each: {
          fio: { required },
          position: { required },
        }
      },
      period_from: { required, underscorePresent },
      period_to: { required, underscorePresent },
      date: {
        required,
        underscorePresent,
        contractsMinDate,
        contractsMaxDate
      }
    }
  },
  computed: {
    userIsHead() {
      return this.$store.state.user_role === 'head'
    },
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
    readonlyCondition() {
      return !this.userIsAdmin && (this.form.status === 2 || this.form.status === 4)
    }
  },
  created() {
    getStatus()
      .then(response => {
        this.status_options = response.data
      })
    getCallLetter(this.$route.params.id)
      .then(response => {
        this.form = {
          ...response.data,
          date: dateFormatting(response.data.date, 'iso-to-normal-small'),
          period_from: dateFormatting(response.data.period_from, 'iso-to-normal-small'),
          period_to: dateFormatting(response.data.period_to, 'iso-to-normal-small'),
        }
      })
      .catch(() => {
        this.$router.push({ name: 'call-letter-list' })
      })
    getDelegations()
      .then(response => {
        this.delegation_options = response.data
      })
  },
  methods: {
    onDownloadApplication() {
      this.download_app = true
      getCallLetterExport(this.$route.params.id)
        .then(response => {
          this.delegation_name = this.delegation_options.find(item => item.id === this.$store.state.delegation).name
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Письмо-вызов ${this.delegation_name} ${this.$route.params.id}.pdf`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .finally(() => {
          this.download_app = false
        })
    },
    onShowModal() {
      this.$modal.show('delete-modal')
    },
    onHideModal() {
      this.$modal.hide('delete-modal')
    },
    onSendFormWithChangeStatus(status) {
      this.status_to_set = status
      this.onCheckForm()
    },
    onDeleteCallLetter() {
      this.$refs.submitDelete.preload = true
      deleteCallLetter(this.$route.params.id)
        .finally(() => {
          this.$refs.submitDelete.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Письмо-вызов удален'
          })
          this.$router.push({ name: 'call-letter-list' })
        })
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      if (this.$refs.submitReview) this.$refs.submitReview.preload = true
      const form = {...this.form}
      form.period_from = dateFormatting(form.period_from, 'normal-to-iso-small')
      form.period_to = dateFormatting(form.period_to, 'normal-to-iso-small')
      form.date = dateFormatting(form.date, 'normal-to-iso-small')
      if (this.status_to_set) form.status = this.status_to_set
      putCallLetter(this.$route.params.id, form)
        .finally(() => {
          this.$refs.submit.preload = false
          if (this.$refs.submitReview) this.$refs.submitReview.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Письмо-вызов изменен'
          })
          this.$router.push({ name: 'call-letter-list' })
        })
    },
    onCloneItem() {
      this.form.list.push({fio: '', position: ''})
      this.$v.form.$touch()
    },
    onDeleteItem(index) {
      this.form.list.splice(index, 1)
    },
  }
}
</script>
